var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"LexendFont","font-size":"30px","font-weight":"400"},style:({
            'font-size':
              _vm.$vuetify.breakpoint.name == 'xs'
                ? '20px'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '20px'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '25px'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          })},[_vm._v("Gallery")])]),(_vm.roomImage)?_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":"","lg8":"","align-self-center":""}},[(_vm.roomImage.length > 0)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-2":""}},[_c('v-flex',{attrs:{"xs8":"","sm12":""}},[_c('viewer',{key:_vm.roomImage.length,attrs:{"images":_vm.roomImage}},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.roomImage),function(item,i){return _c('v-flex',{key:i,staticClass:"pa-1",attrs:{"xs12":"","sm4":"","md3":""}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.mediaUURL + item,"height":"200px","width":"200px"}})])}),1)],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.gallery)?_c('v-flex',{attrs:{"xs12":"","sm11":"","md10":"","lg8":"","align-self-center":"","pt-4":""}},[(_vm.gallery.length > 0)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-2":""}},[_c('v-flex',{attrs:{"xs9":"","sm12":""}},[_c('viewer',{key:_vm.gallery.length,attrs:{"images":_vm.gallery}},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.gallery),function(item,i){return _c('v-flex',{key:i,staticClass:"pa-1",attrs:{"xs12":"","sm4":"","md3":""}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.mediaUURL + item,"height":"200px","width":"200px"}})])}),1)],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }